body {
  margin: 0;
  @apply h-screen;
  font-family: 'Circular Std', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: inherit;
}
.changing-text {
  position: relative;
}

.changing-text span {
  position: absolute;
  left: 0%;
  right: 0%;
  opacity: 1;
  /* animation: changeText 12s linear infinite; */
  animation-name: changeText;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.bg-gradient-to-right {
  background-image: linear-gradient(to right, black, green);
  background-size: 200% 100%;
  background-position: 0% 50%;
}

code {
  font-family: 'Circular Std', sans-serif;
}

@keyframes changeText {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  5% {
    opacity: 1;
    transform: translateY(0px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  15% {
    opacity: 0;
    transform: translateY(0px);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
